import {
  closeModal,
  navigate,
  openModal,
  setIsLoadingAddressesFromServer,
  setIsLoadingLocationFromServer,
  setIsLocationPicked,
  setIsUserLoggedIn,
  setLoadingLocationsSettingsFromServer,
  setLocations,
  setOrganizationFull,
  setPaymentMethods,
  setSavedAddresses,
  setIsMAInstalled,
  setIsRewardInstalled,
  setLoyaltyAccount,
  setLoyaltyRewards,
  setLoyaltyProgram,
  setLoyaltyEarningRules,
  setIsMultiLocation,
  setLockedDineInLocation,
  setLoadingPaymentMethods,
  setSiteLanguage,
  setExperiments,
} from './session.actions';
import { createReducer, pushItem, removeItem } from '../common/reducerUtils';
import { EMPTY_MENU, EMPTY_RESTAURANT } from '../../core/constants';
import {
  CloseModalPayload,
  NavigatePayload,
  OpenModalPayload,
  SetIsLoadingAddressesFromServerPayload,
  SetIsLoadingLocationFromServerPayload,
  SetIsLocationPickedPayload,
  SetIsUserLoggedInPayload,
  SetLoadingLocationsSettingsFromServerPayload,
  SetLocationsPayload,
  SetPaymentMethodsPayload,
  SetSavedAddressesPayload,
  SetIsMAInstalledPayload,
  SetIsRewardInstalledPayload,
  SetLoyaltyAccountPayload,
  SetLoyaltyRewardsPayload,
  SetLoyaltyProgramPayload,
  SetLoyaltyEarningRulesPayload,
  SetIsMultiLocationPayload,
  SetLockedDineInLocationPayload,
  SetLoadingPaymentMethodsPayload,
  SetSiteLanguage,
  SetExperiments,
} from './session.actions.types';
import { ExtractPayload, getItemsHash, ItemsHash, Menu, Restaurant } from '@wix/restaurants-client-logic';
import { IPaymentMethod } from '@wix/cashier-payments-widget/dist/src/cashier-payments';
import { Address } from '@wix/ambassador-addresses-web/types';
import type { PartialLocation } from '../../core/oloApi';
import { Reward } from '@wix/ambassador-loyalty-rewards/http';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-accounts/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-programs/types';
import { EarningRule } from '@wix/ambassador-loyalty-calculator/http';
import { ExperimentsBag } from '@wix/wix-experiments';

interface SessionState {
  restaurant: Restaurant;
  menu: Menu;
  itemsHash: ItemsHash;
  modals: OpenModalPayload[];
  routeUrlOverride?: string;
  paymentMethods: IPaymentMethod[];
  isUserLoggedIn: boolean;
  savedAddresses: Address[];
  defaultAddressId?: string;
  isLoadingAddressesFromServer: boolean;
  isLoadingLocationFromServer: boolean;
  isLoadingLocationsSettingsFromServer: boolean;
  isLocationPicked: boolean; // indicate if a user picked a location to deliver/pickup from or not.
  locations: PartialLocation[];
  loggedInUserEmail?: string;
  isMAInstalled?: boolean;
  isRewardInstalled?: boolean;
  loyaltyRewards?: Reward[];
  loyaltyAccount?: LoyaltyAccount;
  loyaltyProgram?: LoyaltyProgram;
  loyaltyEarningRules?: EarningRule[];
  isMultiLocation?: boolean;
  lockedDineInLocation?: string;
  isLoadingPaymentMethods?: boolean;
  siteLanguage?: string;
  experiments?: ExperimentsBag;
}

const initialState: SessionState = {
  restaurant: EMPTY_RESTAURANT,
  menu: EMPTY_MENU,
  itemsHash: {},
  modals: [],
  paymentMethods: [],
  isUserLoggedIn: false,
  savedAddresses: [],
  isLoadingAddressesFromServer: false,
  isLoadingLocationFromServer: false,
  isLoadingLocationsSettingsFromServer: false,
  isLocationPicked: false,
  locations: [],
  isMAInstalled: false,
  isRewardInstalled: false,
  loyaltyRewards: [],
  loyaltyAccount: {},
  loyaltyEarningRules: [],
  isMultiLocation: false,
  isLoadingPaymentMethods: true,
  siteLanguage: undefined,
  experiments: undefined,
};

const reducer = createReducer<SessionState>(initialState, {
  [setOrganizationFull.toString()]: (state, payload: ExtractPayload<typeof setOrganizationFull>) => {
    const { restaurant, menu } = payload.organizationFull;
    return {
      ...state,
      restaurant,
      menu,
      itemsHash: getItemsHash(menu),
    };
  },
  [openModal.toString()]: (state, payload: OpenModalPayload) => {
    if (state.modals.find(({ modal }) => modal === payload.modal)) {
      console.warn('attempt to open the same modal twice ', payload.modal);
      return state;
    }
    return {
      ...state,
      modals: pushItem(state.modals, payload),
    };
  },
  [closeModal.toString()]: (state, payload: CloseModalPayload) => {
    return {
      ...state,
      modals: removeItem(
        state.modals,
        state.modals.findIndex(({ modal }) => modal === payload.modal),
      ),
      routeUrlOverride: undefined,
    };
  },
  [navigate.toString()]: (state, payload: NavigatePayload) => {
    return {
      ...state,
      routeUrlOverride: payload.routeUrl,
    };
  },
  [setPaymentMethods.toString()]: (state, payload: SetPaymentMethodsPayload) => {
    return {
      ...state,
      paymentMethods: payload.paymentMethods,
    };
  },
  [setIsUserLoggedIn.toString()]: (state, payload: SetIsUserLoggedInPayload) => {
    return {
      ...state,
      isUserLoggedIn: payload.isLoggedIn,
      loggedInUserEmail: payload.isLoggedIn ? payload.loggedInUserEmail : undefined,
    };
  },
  [setSavedAddresses.toString()]: (state, payload: SetSavedAddressesPayload) => {
    return {
      ...state,
      savedAddresses: payload.addresses,
      defaultAddressId: payload.defaultAddressId,
    };
  },
  [setIsLoadingAddressesFromServer.toString()]: (state, payload: SetIsLoadingAddressesFromServerPayload) => {
    return {
      ...state,
      isLoadingAddressesFromServer: payload.isLoadingAddressesFromServer,
    };
  },
  [setIsLoadingLocationFromServer.toString()]: (state, payload: SetIsLoadingLocationFromServerPayload) => {
    return {
      ...state,
      isLoadingLocationFromServer: payload.isLoadingLocationFromServer,
    };
  },
  [setLoadingLocationsSettingsFromServer.toString()]: (
    state,
    payload: SetLoadingLocationsSettingsFromServerPayload,
  ) => {
    return {
      ...state,
      isLoadingLocationsSettingsFromServer: payload.isLoadingLocationsSettingsFromServer,
    };
  },
  [setLocations.toString()]: (state, payload: SetLocationsPayload) => {
    return {
      ...state,
      locations: payload.locations,
    };
  },
  [setIsMAInstalled.toString()]: (state, payload: SetIsMAInstalledPayload) => {
    return {
      ...state,
      isMAInstalled: payload.isMAInstalled,
    };
  },
  [setIsRewardInstalled.toString()]: (state, payload: SetIsRewardInstalledPayload) => {
    return {
      ...state,
      isRewardInstalled: payload.isRewardInstalled,
    };
  },
  [setIsLocationPicked.toString()]: (state, payload: SetIsLocationPickedPayload) => {
    return {
      ...state,
      isLocationPicked: payload.value,
    };
  },
  [setLoyaltyAccount.toString()]: (state, payload: SetLoyaltyAccountPayload) => {
    return {
      ...state,
      loyaltyAccount: payload.loyaltyAccount,
    };
  },
  [setLoyaltyRewards.toString()]: (state, payload: SetLoyaltyRewardsPayload) => {
    return {
      ...state,
      loyaltyRewards: payload.loyaltyRewards,
    };
  },
  [setLoyaltyProgram.toString()]: (state, payload: SetLoyaltyProgramPayload) => {
    return {
      ...state,
      loyaltyProgram: payload.loyaltyProgram,
    };
  },
  [setLoyaltyEarningRules.toString()]: (state, payload: SetLoyaltyEarningRulesPayload) => {
    return {
      ...state,
      loyaltyEarningRules: payload.earningRules,
    };
  },
  [setIsMultiLocation.toString()]: (state, payload: SetIsMultiLocationPayload) => {
    return {
      ...state,
      isMultiLocation: payload.value,
    };
  },
  [setLoadingPaymentMethods.toString()]: (state, payload: SetLoadingPaymentMethodsPayload) => {
    return {
      ...state,
      isLoadingPaymentMethods: payload.loading,
    };
  },
  [setLockedDineInLocation.toString()]: (state, payload: SetLockedDineInLocationPayload) => {
    return {
      ...state,
      lockedDineInLocation: payload.locationId,
    };
  },
  [setSiteLanguage.toString()]: (state, payload: SetSiteLanguage) => {
    return {
      ...state,
      siteLanguage: payload.siteLanguage,
    };
  },
  [setExperiments.toString()]: (state, payload: SetExperiments) => {
    return {
      ...state,
      experiments: payload.experiments,
    };
  },
});

export default reducer;
